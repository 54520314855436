import React, { lazy } from 'react';
import { shallowEqual } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import App from './App';
import PageWithLoginAndSignup from './layout/PageWithLoginAndSignup';
import BShareDialog from './layout/posts/BShareDialog';

import { useAppSelector } from '../store';
import B1000 from './pages/B1000/B1000';
import BPostWithCommentsPage from './pages/BPostWithCommentsPage';

export const ROUTES = {
  myProfileEdit: '/my-profile-edit',
  myPosts: '/my-posts',
  savedChallenges: '/saved-challenges',
  user: '/user/:userId',
  settings: '/settings',
  termsAndConditions: '/terms-and-conditions',
  learnMore: '/learn-more',
  getRewarded: '/get-rewarded',
  getInspired: '/get-inspired',
  post: '/post',
  singlePost: '/post/:id',
  landingPage: '/landing/:id',
  onboarding: '/onboarding',
  home: '/',
};

const AsyncMyProfileEdit = lazy(() => import('./pages/BMyProfile'));
const AsyncSettings = lazy(() => import('./pages/BSettings/BSettings'));

const AsyncTermsAndConditions = lazy(() =>
  import('./pages/BTermsAndConditions')
);

const AsyncProfilePage = lazy(() => import('./pages/BProfilePage'));

const AsyncSavedChallengesPage = lazy(() =>
  import('./pages/BSavedChallengesPage')
);

const AsyncGetRewardedPage = lazy(() => import('./pages/BGetRewardedPage'));

const AsyncGetInspiredPage = lazy(() => import('./pages/BGetInspiredPage'));

const AsyncLearnMorePage = lazy(() => import('./pages/BLearnMore'));
const AsyncOnboardingPage = lazy(() => import('./pages/BOnboarding'));
const AsyncLandingPage = lazy(() =>
  import('./pages/BLandingPage/BLandingPage')
);

const AuthRoute: React.FC<RouteProps & { loggedIn?: boolean }> = ({
  loggedIn: shouldBeLoggedIn = true,
  ...rest
}) => {
  const { isLoggedIn, authInitialized } = useAppSelector(
    ({ auth: { user, authInitialized } }) => ({
      isLoggedIn: !!user,
      authInitialized,
    }),
    shallowEqual
  );

  if (!authInitialized) {
    return null;
  }

  // if (isLoggedIn && !shouldBeLoggedIn) {
  //   return <Redirect to={ROUTES.getInspired} />;
  // }

  if (!isLoggedIn && shouldBeLoggedIn) {
    return <Redirect to={ROUTES.home} />;
  }

  return <Route {...rest} />;
};

const BRouter = () => {
  return (
    <BrowserRouter>
      <App>
        <PageWithLoginAndSignup>
          <Switch>
            <AuthRoute
              path={ROUTES.myProfileEdit}
              component={AsyncMyProfileEdit}
            />
            <AuthRoute path={ROUTES.settings} component={AsyncSettings} />
            <AuthRoute path={ROUTES.myPosts} component={AsyncProfilePage} />
            <AuthRoute
              path={ROUTES.savedChallenges}
              component={AsyncSavedChallengesPage}
            />
            <AuthRoute
              path={ROUTES.user}
              component={AsyncProfilePage}
              loggedIn={false}
            />
            <Route path={ROUTES.getRewarded} component={AsyncGetRewardedPage} />
            <Route
              path={ROUTES.termsAndConditions}
              component={AsyncTermsAndConditions}
            />
            <Route path={ROUTES.getInspired} component={AsyncGetInspiredPage} />
            <Route path={ROUTES.learnMore} component={AsyncLearnMorePage} />
            <Route
              path={`${ROUTES.post}/:id`}
              component={BPostWithCommentsPage}
            />
            <Route
              path={`${ROUTES.landingPage}`}
              component={AsyncLandingPage}
            />
            <AuthRoute
              path={`${ROUTES.onboarding}`}
              component={AsyncOnboardingPage}
              loggedIn={true}
            />
            <AuthRoute
              path={ROUTES.home}
              component={() => <Redirect to={ROUTES.getInspired} />}
              loggedIn={false}
              exact
            />
          </Switch>
          <BShareDialog />
        </PageWithLoginAndSignup>
      </App>
    </BrowserRouter>
  );
};

export default BRouter;
